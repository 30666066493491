jQuery(document).ready(function($) {
    function updateQty(key, qty) {
        var miniCartWidget = $('.woo-minicart');
        let data = {
            action: 'updateMinicart',
            cart_item_key: key,
            cart_item_qty: qty
        }
        miniCartWidget.addClass('updating');

        $.post( woocommerce_params.ajax_url, data ) .done(function( res ) {
            $('body').trigger( 'wc_fragment_refresh' );
            miniCartWidget.removeClass('updating');
        });
    }

    $(document).on('change', '#minicart-widget input.qty', function() {
        let itemKey = $(this).closest('.mini_cart_item').find('a.remove').data('cart_item_key');
        updateQty(itemKey, parseInt(this.value));
    })

});

