document.addEventListener("DOMContentLoaded", function() {

    var animatedNumbersRun = false;
    function animateValue(element) {

        var obj = element;
        var end = parseInt(obj.dataset.end);
        var pad = parseInt(end.toString().length);

        var current = 0;
        var increment = parseInt(obj.dataset.end / 10);

        var stepTime = 80;

        var timer = setInterval(function() {
            current += increment;
            obj.innerHTML = current.toString();
            if (current > end) {
                obj.innerHTML = end.toLocaleString('et-EE');
                clearInterval(timer);
            }
        }, stepTime);
    }

    var scrollingNumbers = [].slice.call(document.querySelectorAll(".countable"));

    var numbersObserver = new IntersectionObserver(function(entries, observer) {
        entries.forEach(function(entry) {
            if (entry.isIntersecting) {
                animateValue(entry.target);
                numbersObserver.unobserve(entry.target);
            }
        });
    });

    scrollingNumbers.forEach(function(element) {
        numbersObserver.observe(element);
    })

    for (var i = 0; i < scrollingNumbers.length; i++) {
        animateValue(scrollingNumbers[i]);
    }

    var lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));
    var pageElements = [].slice.call(document.getElementsByClassName("animate"));
    if ("IntersectionObserver" in window) {
        var lazyImageObserver = new IntersectionObserver(function(entries, observer) {
            entries.forEach(function(entry) {
                if (entry.isIntersecting) {
                    let lazyImage = entry.target;
                    lazyImage.src = lazyImage.dataset.src;
                    lazyImage.srcset = lazyImage.dataset.srcset;
                    lazyImage.sizes = lazyImage.dataset.sizes;
                    lazyImage.classList.remove("lazy");
                    lazyImage.classList.add('visible');
                    lazyImageObserver.unobserve(lazyImage);

                    if (lazyImage.parentElement.classList.contains('lazyBg')) {
                        lazyImage.parentElement.classList.add('visible');
                        lazyImage.addEventListener('load', function() {
                            var imgSrc = (this.currentSrc) ? this.currentSrc : this.getAttribute('src');
                            lazyImage.parentElement.style.background = 'url(' + imgSrc + ') no-repeat center';
                            lazyImage.parentElement.style['background-size'] = 'cover';
                        })
                    }
                    if (lazyImage.parentElement.classList.contains('loading')) {
                        lazyImage.addEventListener('load', function() {
                            this.parentElement.classList.remove('loading');
                        });
                    }
                }
            });
        });

        lazyImages.forEach(function(lazyImage) {
            lazyImageObserver.observe(lazyImage);
        });

        let elementsObserver = new IntersectionObserver(function(entries, observer) {
            entries.forEach(function(entry) {
                if (entry.isIntersecting) {
                    let element = entry.target;
                    element.classList.add('visible');
                    elementsObserver.unobserve(element);
                }
            });
        });

        pageElements.forEach(function(element) {
            elementsObserver.observe(element);
        })
    }

});
