jQuery(document).ready(function ($) {

    $('.products .product a.block').on('click', function (e) {
        if (window.innerWidth < 768) {
            e.preventDefault();
            e.stopPropagation();
            $(this).closest('.product').addClass('focus');
            $('body').addClass('layer-light');
        }
    })

    $('.product .overlay .close').on('click', function () {
        $(this).closest('.product').removeClass('focus');
        $('body').removeClass('layer-light');
    })

    $('.toggleFilter').on('click', function(e) {
        e.preventDefault();
        $('.product-filters').toggle();
    })

    $('.add25').on('click', function() {
        $('form.cart input.qty').val(25);
    });

});