import autoComplete from "@tarekraafat/autocomplete.js";

let autoCompleteElement = document.querySelector("#autoComplete");
if (autoCompleteElement) {
    let resultsLoaded = false;
    autoCompleteElement.addEventListener('keyup', function() {
        if (this.value.length > 1 && !resultsLoaded) {
            resultsLoaded = true;
            let ajaxUrl = woocommerce_params.ajax_url;
            let requestData = new FormData();
            requestData.append('action', 'getProducts');
            let placeholderText = this.getAttribute('placeholder');
            new autoComplete({
                placeHolder: placeholderText,
                data: {
                    src: async (query) => {
                        try {
                            // Fetch Data from external Source
                            const source = await fetch(ajaxUrl , {
                                method: 'POST',
                                body: requestData
                            });
                            const data = await source.json();
                            return data;
                        } catch (error) {
                            return error;
                        }
                    },
                    keys: ["name"],
                    cache: true
                },
                resultsList: {
                    maxResults: 15,
                    tabSelect: true
                },
                resultItem: {
                    element: (item, data) => {
                        console.log(data)
                        item.style = "display: flex; gap:12px;";
                        item.innerHTML = `
               ${data.value.img}
               <div>
              <span style="text-overflow: ellipsis; overflow: hidden;">
                ${data.match}
              </span>
              <span style="display: flex; align-items: center; gap:12px; font-size: 14px;">
                ${data.value.price}
              </span></div>`;
                    },
                    highlight: true,
                }
            });
            this.focus();
        }

    })



    document.querySelector("#autoComplete").addEventListener("selection", function (event) {
        window.location = event.detail.selection.value.url;
    });
}



