import './scss/styles.scss';

import './js/aos';
import './js/lazyload';
import './js/main';
import './js/app';
import './js/search';
import './js/slider';

//import './js/scrolling-video';

import './js/product';

// Dynamic imports for perfomance optimization

if (document.querySelector('.animatev')) {
    import('./js/scrolling-video').then(scrollVideo => {
        scrollVideo.default.init();
    })
}


if (document.querySelector('.scroll-tabs')) {
    import('./js/scrolling-tabs').then(scrollTabs => {
        scrollTabs.default.init();
    })
}

if (document.body.classList.contains('page-template-page-product_picker')) {
    import('./js/product-picker').then(ProductPicker => {
        ProductPicker.default.init();
    })
}


