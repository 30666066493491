jQuery(document).ready(function ($) {

    if (document.querySelector('section.product-tabs')) {

        var categorySlider = new Swiper('.categories-slider .swiper', {
            loop: false,
            centeredSlides: false,
            slidesPerView: 3,
            //activeSlideIndex: 2,

            speed: 400,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            breakpoints: {
                600: {
                    slidesPerView: 3,
                },
                1200: {
                    slidesPerView: 5,
                }
            }
        });

        categorySlider.on('slideChange', function () {
            loadProductsSlider(this.activeIndex);
        });

        //categorySlider.slideTo(2, false, false);

    }


    function loadProductsSlider(idx) {
        $('.product-tabs li').removeClass('active');
        let selectedTab = $('.product-tabs li:nth-child(' + (idx+1) + ')');
        selectedTab.addClass('active');
        let requestData = {
            'action': 'getCategoryProducts',
            'category': selectedTab.data('cat')
        };

        $('.tab-panels .panel').css('opacity', 0.1);

        $('.loader').show();
        $.post(woocommerce_params.ajax_url, requestData, function (res) {
            $('.tab-panels .panel').html(res).fadeIn('slow').css('opacity', 1);
            initProductCategorySliders();
            $('.loader').hide();
        });
    }

    $('.product-tabs li').on('click', function () {
        let idx = $(this).data('idx');
        categorySlider.slideTo(idx);
        loadProductsSlider(idx)

    })
});


function initProductCategorySliders() {
    new Swiper('.product-slider .swiper', {
        loop: false,
        //centeredSlides: true,
        slidesPerView: 2.2,
        speed: 400,
        spaceBetween: 48,
        freeMode: true,
        simulateTouch: true,
        allowTouchMove: true,
        mousewheel: {
            forceToAxis: true
        },
        breakpoints: {
            800: {
                slidesPerView: 3,
                spaceBetween: 16
            },
            1200: {
                slidesPerView: 4,
                spaceBetween: 32
            },
            1440: {
                slidesPerView: 5
            }
        }
    });
}

if (document.querySelector('section.product-tabs')) {
    initProductCategorySliders();
}

if (document.querySelector('section.bestsellers')) {
    const swiper = new Swiper('.bestsellers .swiper', {
        loop: true,
        //centeredSlides: true,
        slidesPerView: 2,
        //freeMode: false,
        simulateTouch: true,
        allowTouchMove: true,
        mousewheel: {
            forceToAxis: true
        },
        speed: 400,
        spaceBetween: 32,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        autoplay: {
            delay: 1500,
            disableOnInteraction: false,
            pauseOnMouseEnter: true
        },
        breakpoints: {
            800: {
                slidesPerView: 3,
            },
            1200: {
                slidesPerView: 5,
                spaceBetween: 60
            }
        }
    });
}

jQuery(document).ready(function ($) {
    let pageSliders = document.querySelectorAll('.page-slider .swiper');

    if (pageSliders) {
        pageSliders.forEach((slider) => {

            let items = slider.dataset.items ? slider.dataset.items : 1;
            let autoplay = slider.dataset.autoplay ? slider.dataset.autoplay : false;
            let pause = slider.dataset.pause ? slider.dataset.pause : false;
            let dots = !!slider.dataset.dots;
            let arrows = !!slider.dataset.arrows;
            let gap = slider.dataset.gap ? slider.dataset.gap : false;
            let center = slider.dataset.center ? slider.dataset.center : false;
            let fade = !!slider.dataset.fade;

            let sliderArgs = {
                loop: false,
                centered: center,
                slidesPerView: 1,
                speed: 1000,
                spaceBetween: gap,
                //freeMode: true,
                simulateTouch: true,
                allowTouchMove: true,
                mousewheel: {
                    forceToAxis: true
                },
                breakpoints: {
                    360: {
                        slidesPerView: (items > 2) ? 1.5 : 1,
                    },
                    480: {
                        slidesPerView: (items > 2) ? 2 : 1,
                    },
                    800: {
                        slidesPerView: (items > 3) ? 3 : items,
                    },
                    1200: {
                        slidesPerView: items
                    },
                    1440: {
                        slidesPerView: items
                    }
                }
            };

            if (fade) {
                sliderArgs['effect'] = 'fade';
                sliderArgs['speed'] = 500;
                sliderArgs['fadeEffect'] = {crossFade: false};
            }

            if (autoplay) {
                sliderArgs['autoplay'] = {
                    delay: autoplay,
                    disableOnInteraction: false
                };
                if (pause) {
                    sliderArgs['autoplay']['pauseOnMouseEnter'] = true;
                }
            }

            if (dots) {
                sliderArgs['pagination'] = {
                    el: ".swiper-pagination",
                    clickable: true
                };
            }

            if (arrows) {
                sliderArgs['navigation'] =
                {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                }
            }

            new Swiper(slider, sliderArgs);

        });

    }
})

